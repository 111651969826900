import React from 'react'
import { Flex, Input, Button, Textarea, Heading } from '@theme-ui/components'
export default function CookingWithUsForm({ city, isMultiLocationSite }) {
  return (
    <Flex
      sx={{
        padding: '3rem 1rem',
        flexDirection: 'column',
        backgroundColor: '#fff8e8'
      }}
    >
      <Heading as="h2" sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        Request A Quote
      </Heading>
      <Flex
        sx={{
          margin: '0 auto',
          'input, textarea': {
            border: 'none',
            borderRadius: '0px',
            borderBottom: 'solid grey 1px'
          }
        }}
        as="form"
        variant={`${'contactFormV1'}.formContainer`}
        name="Cooking With Us Form" //  this can not be dyanmic form names and details must be static
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <Input type="hidden" name="form-name" value="Cooking With Us Form" />
        <Input
          variant={`${'contactFormV1'}.name`}
          name="name"
          type="text"
          id="name"
          mb={3}
          placeholder="Name"
          className="inputField name"
        />
        <Input
          variant={`${'contactFormV1'}.email`}
          type="email"
          name="email"
          id="email"
          mb={3}
          placeholder="Email"
          className="inputField email"
        />
        <Flex>
          <Input
            sx={{ marginRight: '0.5rem' }}
            variant={`${'contactFormV1'}.date`}
            type="date"
            name="date"
            id="date"
            mb={3}
            className="inputField Date"
          />
          <Input
            sx={{ marginLeft: '0.5rem' }}
            variant={`${'contactFormV1'}.email`}
            type="number"
            name="numberofpeople"
            id="people"
            mb={3}
            placeholder="Number of People"
            className="inputField people"
          />
        </Flex>
        <Input
          variant={`${'contactFormV1'}.location`}
          name="location"
          type="text"
          id="location"
          mb={3}
          placeholder="Location"
          className="inputField location"
        />
        <Textarea
          name="message"
          id="message"
          variant={`${'contactFormV1'}.message`}
          rows="6"
          mb={3}
          placeholder="Message"
          className="textField message"
        />
        {isMultiLocationSite ? (
          <Input sx={{ display: 'none !important' }} name="location" id="location" value={city} placeholder={city} />
        ) : (
          ''
        )}
        <Button className="button submitBtn">Submit</Button>
      </Flex>
    </Flex>
  )
}
