// ?===============================
// ?=====  3rd party Imports  =====
// ?===============================

import React, { useEffect, useState, useContext } from 'react'
import { graphql } from 'gatsby'
import { Flex, useThemeUI, Box } from 'theme-ui'
import shortid from 'shortid'
import { TinyButton as ScrollUpButton } from 'react-scroll-up-button' //Add this line Here
import { useLocation } from '@reach/router'

// ?===================================
// ?===  Local Components Imports  ====
// ?===================================

import Layout from '../../../../gatsby-theme-minimal/src/components/Layout/Layout'
import SEO from '../../../../gatsby-theme-minimal/src/components/Layout/seo'
import { store } from '../../../../gatsby-theme-minimal/src/Context/Store'
import ContactFormV1 from '../../../../gatsby-theme-minimal/src/componentsList/ContactFormV1'
import CookingWithUsForm from '../components/CookingWithUsForm'
import YourDinnerPartyForm from '../components/YourDinnerPartyForm'
import OpeningPage from '../../../../gatsby-theme-minimal/src/components/OpeningPages/OpeningPageV1'

// ?===================================
// ?====  Local Function Imports  =====
// ?===================================

import checkForCustomLinks from '../../../../gatsby-theme-minimal/src/helpers/checkForCustomLinks'
import importPageComponents from '../../../../gatsby-theme-minimal/src/helpers/importPageComponents'
import capitalizeFirstLetter from '../../../../gatsby-theme-minimal/src/helpers/capitalizeFirstLetter'

// ?===================================
// ?========  Style Imports  ==========
// ?===================================

import '../../../../gatsby-theme-minimal/src/index.css'
import 'react-image-lightbox/style.css'
import useScrollToElementByURLHash from '../../../../gatsby-theme-minimal/src/hooks/UI/useScrollToElementByURLHash'

// This Template is used on every generated page on the site. apart from the multi location Landing page.
// its job is to get the data from the page context and graphql query and then funnel it to the right locations.
// data has to passed into two places. The Layout(i.e navigation and footer.) and the dyanmic component renderer
// this componenet also handle a dynamic theme color. passing the data into theme UI.

// ?===================================
// ?======  Template Component  =======
// ?===================================

const PageTemplate = props => {
  // ?===========================
  // ?===== Component State =====
  // ?===========================
  const { globalState, dispatch } = useContext(store)
  const [components, setComponents] = useState(null) // where the pages componenets are stored after dynamically importing

  // ?=================
  // ?===== Hooks =====
  // ?=================
  const routeLocation = useLocation()
  useScrollToElementByURLHash() // feature that Smooth scrolls to the element if url has a #hash Anchor

  // ?==========================
  // ?====== Destructing  ======
  // ?==========================

  const { pageContext, data, location } = props
  const { businessData, poweredImages, allConfigData } = data
  const configData = data.allConfigData.edges[0].node
  const allGeneral = data.allGeneral.edges.map(({ node }) => node) // removes node layer
  const { metaTitle, metaDescription, keywords } = configData
  const { name, desc, city, avatar, tags } = businessData

  // PAGE Context data that comes from gatsby node file gets desctructed from here
  const {
    // gonationID,
    pageObjectKey,
    // locationIndex,
    // city,
    pageComponents,
    customPageName,
    locations,
    isAdditionalPage
  } = pageContext

  // ?==============================
  // ?=====  Variable Creation  ====
  // ?==============================

  // grabs the theme ui object for use
  const themeContext = useThemeUI().theme
  // this determines the page title used in the navigation.
  // if its custom page name use it. if not use the pageobject keywhich is the default name
  const pageTitle = `${customPageName !== '' ? customPageName : pageObjectKey}`
  const isMultiLocationSite = locations.length > 1

  // ?=============================================
  // ?==============   Functions   ================
  // ?=============================================

  // this gets the components for this page and dyanmically renders them and gives them all the props possible
  // so every componenet has access to any static data generated by the gatsy node files.
  const getComponents = async () => {
    // the components props data is passed in here
    const data = await importPageComponents(pageComponents, {
      ...pageContext,
      businessData,
      configData,
      allGeneral,
      poweredImages,
      pageTitle,
      isMultiLocationSite,
      isAdditionalPage
    })
    // once the components have been import and the promise has been finished set the components to the state.
    // ready for rendering
    setComponents(data)
  }

  // ?============================================
  // ?====== Dynamic Font Loading Operators ======
  // ?============================================

  const googleFamilies = themeContext.fonts.googleFonts ? themeContext.fonts.googleFonts : ['Roboto'] // without passing anything it breaks

  const customFamilies = themeContext.fonts.customFamilies ? themeContext.fonts.customFamilies : ['']
  const customUrls = themeContext.fonts.customUrls ? themeContext.fonts.customUrls : ['']

  // ===================================================
  // ==============   ON/OFF MOUNTING   ================
  // ===================================================

  useEffect(() => {
    dispatch({
      type: 'SET_SITE_DATA',
      data: {
        ...pageContext,
        businessData,
        configData,
        poweredImages,
        allGeneral,
        pageTitle,
        isMultiLocationSite,
        isAdditionalPage
      }
    })

    // on load of the template/page get the components
    getComponents()

    if (typeof window !== `undefined`) {
      var WebFont = require('webfontloader')
      // uses the webfont loadder library to dynamically load the right fonts
      WebFont.load({
        google: {
          families: googleFamilies
        },
        custom: {
          families: customFamilies,
          urls: customUrls
        }
      })
    }

    return () => {}
  }, [])

  // this use effect is to keep the site out of screen until the whole site has loaded so animations don't run while the page is loading.
  useEffect(() => {
    if (components) {
      setTimeout(() => {
        window.scrollTo({ top: 0 })
        const pageWrapper = document.getElementById('pageContainer')
        pageWrapper.style.paddingTop = '0rem'
        pageWrapper.style.opacity = '1'
      }, 1600)
    }
    return () => {}
  }, [components])

  return (
    <Box className={`${pageObjectKey}`}>
      <Layout
        {...pageContext}
        navLinks={checkForCustomLinks(configData)}
        businessData={businessData}
        configData={configData}
        isMultiLocationSite={locations.length > 1}
        location={location}
        isAdditionalPage={isAdditionalPage}
      >
        <SEO
          title={
            metaTitle
              ? `${metaTitle} | ${capitalizeFirstLetter(pageTitle)} | ${city}`
              : `${name} | ${capitalizeFirstLetter(pageTitle)} | ${city}`
          }
          description={metaDescription ? `${metaDescription}` : `${desc}`}
          keywords={keywords ? `${keywords}` : `${[]}`}
          favicon={avatar.imageBaseUrl + '/' + avatar.imagePrefix}
          pageTitle={pageTitle}
          city={city}
        />
        <Flex
          sx={{
            flexDirection: 'column',
            paddingTop: '150vh', // prevents section loading ine one go and stop animations from getting loaded and is reset to 0 using a useeffect once everything has loaded.
            opacity: '0'
          }}
          className="pageContainer"
          id="pageContainer"
        >
          {components ? (
            components.map(Component => {
              return <Component key={shortid.generate()} />
            })
          ) : (
            <OpeningPage />
          )}

          {pageObjectKey === 'cooking-with-us' ? (
            <CookingWithUsForm city={city} isMultiLocationSite={isMultiLocationSite} />
          ) : (
            ''
          )}

          {pageObjectKey === 'your-dinner-party' ? (
            <YourDinnerPartyForm city={city} isMultiLocationSite={isMultiLocationSite} />
          ) : (
            ''
          )}

          {pageObjectKey === 'contact' ? (
            <ContactFormV1
              {...pageContext}
              businessData={businessData}
              configData={configData}
              pageTitle={pageTitle}
              isMultiLocationSite={isMultiLocationSite}
              variantName={configData.contactForm.variantName}
              options={configData.contactForm.options}
            />
          ) : (
            ''
          )}
        </Flex>

        <ScrollUpButton
          style={{
            bottom: '0.5rem',
            left: '0.5rem',
            zIndex: '500',
            backgroundColor: 'rgb(255 255 255 / 45%)'
          }}
        />
      </Layout>
    </Box>
  )
}

export default PageTemplate

export const query = graphql`
  query pageTemplateQueryFed($city: String, $locationIndex: Int) {
    businessData(city: { eq: $city }) {
      ...businessDataFields
    }

    poweredImages {
      ...poweredImagesData
    }

    allConfigData {
      edges {
        node {
          ...allConfigDataFields
        }
      }
    }
    allGeneral(filter: { locationIndex: { eq: $locationIndex } }) {
      edges {
        node {
          ...generalArticleData
        }
      }
    }
  }
`

// // graph ql page query is here
// export const query = graphql`
//   fragment optionFieldsFedPageTemplate on componentOptions {
//     title
//     titles
//     subtitle
//     subtitles
//     text
//     texts
//     image
//     images
//     date
//     dates
//     caption
//     captions
//     interval
//     background
//     backgrounds
//     backgroundColor
//     backgroundColors
//     innerBackgroundColor
//     backgroundImage
//     backgroundImages
//     link
//     links
//     ctaName
//     ctaNames
//     ctaLink
//     ctaLinks
//     ctaClassName
//     containerStyles
//     shoutContainerStyles
//     removeAlbums
//     logoHero
//     specialEventsOnly
//     menuImages
//     popUpContentBoxes
//     contactDetails
//     onlineOrderLinks
//     onlineOrderCTAName
//     onlineOrderClassName
//     reservationLinks
//     reservationCTAName
//     reservationClassName
//     deliveryLinks
//     deliveryCTAName
//     deliveryClassName
//     phoneHidden
//     directionsHidden
//     poweredIDs
//     containerID
//     onlineOrderSectionAnchorPath
//     socialLinksHidden
//     callSectionAnchorPath
//     directionSectionAnchorPath
//     reservationSectionAnchorPath
//     deliverySectionAnchorPath
//     hideNavLinks
//     restrictCharacters
//     removeSectionNavLinks
//     quotes
//     galleryData
//     email
//     phoneNumbers
//     addresses
//     emails
//     linkedin
//     pinterest
//     navigationLogo
//     scriptWidget
//     scriptId
//     menuItemLinkNames
//     menuItemLinks
//     video
//     eventTagsToShow
//     eventTagsToHide
//     instagramWidgetSrc
//   }

//   fragment pageComponentFieldsFedPageTemplate on pageComponent {
//     componentName
//     variantName
//     options {
//       ...optionFields
//     }
//   }

//   query fedPageTemplateQuery($city: String) {
//     businessData(city: { eq: $city }) {
//       city
//       desc
//       links {
//         facebook
//         instagram
//         twitter
//         website
//         youtube
//       }
//       id
//       loc
//       name
//       phone
//       slug
//       state
//       street
//       zip
//       cover {
//         imageBaseUrl
//         isDefault
//         imagePrefix
//       }
//       avatar {
//         imageBaseUrl
//         imagePrefix
//         isDefault
//       }
//       hours {
//         mon {
//           close
//           name
//           open
//         }
//         tue {
//           close
//           name
//           open
//         }
//         wed {
//           close
//           name
//           open
//         }
//         thu {
//           close
//           name
//           open
//         }
//         fri {
//           close
//           name
//           open
//         }
//         sat {
//           close
//           name
//           open
//         }
//         sun {
//           close
//           name
//           open
//         }
//       }
//     }
//     allConfigData {
//       edges {
//         node {
//           themeName
//           metaTitle
//           metaDescription
//           alternativeGoogleMapLink
//           navigation {
//             version
//             variantName
//             additionalNavLinks {
//               ctaName
//               ctaLink
//               className
//               order
//             }
//             options {
//               ...optionFieldsPageTemplate
//             }
//           }
//           footer {
//             version
//             variantName
//             additionalNavLinks {
//               ctaName
//               ctaLink
//               className
//               order
//             }
//             options {
//               ...optionFieldsPageTemplate
//             }
//           }
//           ctaWidget {
//             version
//             variantName
//             options {
//               ...optionFieldsPageTemplate
//             }
//           }
//           contactForm {
//             componentName
//             variantName
//             options {
//               ...optionFieldsPageTemplate
//             }
//           }
//           home {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//           about {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//           menu {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//           events {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//           gallery {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//           contact {
//             customPageName
//             pageComponents {
//               componentName
//               variantName
//               options {
//                 ...optionFieldsPageTemplate
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
